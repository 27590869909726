import React from 'react';
import qInputStyle from "../../styles/_components/_maintenance/_question_date.module.scss";
import InputDate from "../../components/_maintenance/input_date";

const  QuestionDate = ({question, name, required=false, _onChange, inputs, error, _popperPlacement="bottom", _classContainer=""}) => {

    return (
        <div className={`${qInputStyle.question__container} ${_classContainer}`}>
            <label htmlFor={name}>
                <h3 className={qInputStyle.question__text}>{question}</h3>
            </label>
            <InputDate name={name} required={required} _onChange={_onChange} inputs={inputs} error={error} _popperPlacement={_popperPlacement}/>
        </div>
    );
};

export default QuestionDate; 