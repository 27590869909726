import { useState, useEffect } from 'react';
import { isNullOrUndefined } from 'util';

const useForm = (callback, _inputs, validate, onStep, terms, setTermsError) => {
    const [inputs, setInputs] = useState(_inputs);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isChanginStep, setIsChanginStep] = useState(false);
    const [step, setStep] = useState(0);
    const [isFaultReport, setIsFaultReport] = useState(false)

    const handleSubmit = (event) => {
      //console.log('event handleSubmit', event);
      if (event) {
        event.preventDefault();
        setIsSubmitting(true);
        // console.log('inputs : ', inputs);
      }
      // validacion para el checkbox
        if(!terms && setTermsError){
            setTermsError(true)
        }
      // validacion para los formularios con un solo paso
      if(onStep === true){
        setErrors(validate(inputs, 2))
      }else{
        setErrors(validate(inputs, step))
      }
    }

    const handleNextStep = () =>{
      if(step === 0 && onStep === true){
        //validar 
        setIsChanginStep(true)
        setErrors(validate(inputs, step))
      }else if(step === 1){
        setIsChanginStep(true)
        setErrors(validate(inputs, step))
      }
      else{
        setStep(step + 1);
      }
    }
  
    function handleBackStep(){
      setStep(step - 1);
    }

    const handleInputChange = (event) => {
      if(isNullOrUndefined(event.isButton) && isNullOrUndefined(event.isDate)){
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value.trim()}));
      }else if(!isNullOrUndefined(event.isButton)){
        setInputs(inputs => ({...inputs, [event.target.name]: {uid: event.uid,  value: event.target.value.trim()}}));
       
        if(event.target.value === "REPORTE DE FALLA"){
           setIsFaultReport(true)
        }else{
          setIsFaultReport(false)
        }
        
      } else if(!isNullOrUndefined(event.isDate)){
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value.trim()}));
      }
    }

    useEffect(()=>{
      // console.log('inputs', inputs)
    },[inputs])

    useEffect(() => {
      
      if((Object.keys(errors).length === 0 && isChanginStep)){
        // console.log('se cambio a step 2')
        setIsChanginStep(false);
        setStep(step + 1);
      }else if (Object.keys(errors).length === 0 && isSubmitting){
        setIsSubmitting(false)
        callback();
      }
     // console.log('errors', errors)
      // eslint-disable-next-line
    }, [errors]);

    return {
      handleSubmit,
      handleInputChange,
      inputs,
      errors,
      handleNextStep,
      step,
      handleBackStep,
      isFaultReport
    };
  }

export default useForm;  