import React from 'react';
import { Link } from "gatsby"
import  ArrowHead from '../../components/svg/commons/arrow_head'
import styles from '../../styles/_components/_buttons/_button_arrow.module.scss';
export const TYPES = {
    NEXT: 'next',
    PREV: 'prev',
    UP: 'up',
    DOWN: 'down',
  }
export const POSITION = {
    BEFORE: 'before',
    AFTER: 'after',
  }
  
export const ButtonArrow= ({_className,_text,_to,_section,_onClick,_type="next", _position="after",_textClass='', _classIcon=''})=>{
    
    return (
        _onClick ?
        <div className={`${styles.oBtn_arrow} ${_className}`} onClick={_onClick}>
            {_position==="before" && <div className={`${styles.oBtn_arrow__icon} ${styles[_type]} ${styles[_position]} ${styles[_classIcon]}`}>
                <ArrowHead section={_section} ></ArrowHead></div>}
            <p className={`${styles.oBtn_arrow__text} ${_textClass}`}>{_text}</p>
            {_position==="after" && <div className={`${styles.oBtn_arrow__icon} ${styles[_type]} ${styles[_position]}`}>
                <ArrowHead section={_section} ></ArrowHead></div>}
        </div>
        :
        <Link className={`${styles.oBtn_arrow} ${_className}`} to={_to}>
            {_position==="before" && <div className={`${styles.oBtn_arrow__icon} ${styles[_type]} ${styles[_position]}`}><ArrowHead section={_section} ></ArrowHead></div>}
            <p className={`${styles.oBtn_arrow__text} ${_textClass}`}>{_text}</p>
            {_position==="after" && <div className={`${styles.oBtn_arrow__icon} ${styles[_type]} ${styles[_position]}`}><ArrowHead section={_section} ></ArrowHead></div>}
        </Link>
    )
}

