
const validateEmail =  function(value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
}

export default function validate(values, step) {
    let errors = {};

    // console.log('value  validations' ,  values)
    /////validation for empty required fields///////
    for(let key in values){
        const element = document.getElementById(key);
        if(element && element.hasAttribute('required') && !values[key].trim()){
            errors[key] = `*Campo obligatorio`;
        }
    }

    switch(step){
        case 1:

            break;
        case 2:
                if (!validateEmail(values.email.trim()) && !errors.email) {
                     errors.email = 'Formato de email invalido';
                }
                if (!/^[0-9]{10}$/.test(values.phone.trim()) && !errors.phone) {
                     errors.phone = 'Formato de telefono invalido';
                }
                if(values.codigo_postal){
                    if (!/^([0-9])*$/.test(values.codigo_postal.trim()) && !errors.codigo_postal) {
                        errors.codigo_postal = 'solo se aceptan números';
                   }
                }
               
        break; 
        default:
        break       
    }
    
    // console.log('errors: ', errors)
    return errors;
  };

