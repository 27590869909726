import React from 'react';
import inputStyle from '../../styles/_components/_maintenance/_input_underlined.module.scss'

const  InputUnderlined = ({placeholder, name, required, type, _className, onChange, inputs, _classContainer, error, maxlength}) => {
    return (
        <div className={inputStyle.input__wrapper}>
            <div className={`${inputStyle.input__container} ${inputStyle[_classContainer]}`}>
                <input className={`${inputStyle.input} ${inputStyle[_className]}`} placeholder={placeholder} name={name} id={name} required={required} type={type} onChange={onChange} value={inputs.name} maxLength={maxlength}/>
            </div>
                <p className={inputStyle.warning__required}>{ error ? error : null}</p>
        </div>
    );
};

export default InputUnderlined; 