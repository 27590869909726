import React from 'react';
import qInputStyle from "../../styles/_components/_maintenance/_question_input.module.scss";
import InputUnderlined from "../../components/_maintenance/input_underlined";

const  QuestionInput = ({question, placeholder, name, required=false, type="text", _className="", _classContainer="", isContact=false, _onChange, inputs, error, maxlength="", _classInputContainer=""}) => {

    return (
        <div className={ isContact ? 
                        `${qInputStyle.question__container}  ${qInputStyle[_classContainer]}`
                        :
                        `${qInputStyle.question__container} ${_classContainer}` }>
            <label htmlFor={name}>
                <h3 className={`${qInputStyle.question__text} ${qInputStyle[_className]}`}>{question}</h3>
            </label>
            <InputUnderlined _classContainer={_classInputContainer} error={error} placeholder={placeholder} name={name} required={required} type={type} _className={_className} onChange={_onChange} inputs={inputs} maxlength={maxlength}/>
        </div>
    );
};

export default QuestionInput; 