import React, {useState, useRef} from 'react';
import qButtonStyle from "../../styles/_components/_maintenance/_question_button.module.scss";
import DropdownForm from "../../components/_maintenance/DropdownForm";
// import InputUnderlined from './input_underlined';

const  QuestionButton = ({question, _classContainer="", _classh3="", dropdownList, _onChange, inputs, name, error, isDropdownModels=false, isEnergyDropdown=false }) => {
//    console.log('inputs[name]', inputs[name])
    const [filterItem, setFilterItem] = useState(isDropdownModels ? filterDropValueModel(inputs[name].uid)[0] : filterDropDownValue(inputs[name].uid)[0]);
    // const [itemDefault, setItemDefault] = useState(false)
    const input = useRef(null)

    function filterDropDownValue(itemId){
        const selectValue = dropdownList.filter(item => item.uid === itemId );
        return selectValue;
    }

    function filterDropValueModel(itemId){
        //console.log('item id',itemId)
        //console.log('dropdownList de modelo',dropdownList)
        let selectValue = [];
        
     // let flag = false;
        for(let i = 0; i < dropdownList.length; i++){
            selectValue = dropdownList[i].versions.filter(item =>item.uid === itemId)
            if(selectValue.length > 0){
                break;
            }
        }
        //console.log('selectValue',selectValue)
        return selectValue;
    }

    function getDropdownValue(itemId) {
        //console.log('itemId    en dropdaun regular',itemId)

		const selectValue = filterDropDownValue(itemId);
        setFilterItem(selectValue[0])
        input.current.value = selectValue[0].value;
        const value = {
            isButton: true,
            uid: itemId,
            target: {
                name: input.current.name,
                value: input.current.value
            }
        }
        _onChange(value);
    }

    function getDropdownValueModel(itemId) {
       // console.log('itemId',itemId)
        // setItemDefault(true)
       
       const selectValue = filterDropValueModel(itemId);
        setFilterItem(selectValue[0])
         input.current.value = selectValue[0].data.title.text;
        //  input.current.value = selectValue[0].value;
        const value = {
            isButton: true,
            uid: itemId,
            target: {
                name: input.current.name,
                value: input.current.value
            }
        }
        _onChange(value);
    }
    return (
        <div className={`${qButtonStyle.question__container} ${qButtonStyle[_classContainer]}`}>
            <h3 className={`${qButtonStyle.question__text} ${qButtonStyle[_classh3]}`}>{question}</h3>
            {
                isDropdownModels ? 
                    <DropdownForm isEnergyDropdown={isEnergyDropdown} isDropdownModels={isDropdownModels} items={dropdownList} default_item={filterItem.data.title.text } handleItemClick={getDropdownValueModel} />
                :
                <DropdownForm isEnergyDropdown={isEnergyDropdown} isDropdownModels={isDropdownModels} items={dropdownList} default_item={filterItem.value} handleItemClick={getDropdownValue} />
            } 
            <input ref={input} type="hidden" name={name} id={name} value={inputs.name}/>
        </div>
    );
};

export default QuestionButton; 