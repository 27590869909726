import React, {useState, useRef, useEffect} from 'react';
import DatePicker, 
{ registerLocale, 
    // setDefaultLocale 
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/_components/_maintenance/_custom_maintenance_datepicker.scss"
import inputStyle from '../../styles/_components/_maintenance/_input_date.module.scss'
import { isNullOrUndefined } from 'util';
import IconArrow from '../svg/commons/arrow_head';
import es from 'date-fns/locale/es';
//import useOutsideClick from '../../hooks/useOutsideClick'
registerLocale('es', es)

const  InputDate = ({name, required, _onChange, inputs, error,_popperPlacement}) => {
    const iconContainerRef = useRef(null)
    const refDatePicker = useRef(null)
    //const [isActive, setActive] = useState(false);

    useEffect(()=>{
        let calendars = document.getElementsByClassName('react-datepicker__input-container')
        //('calendars',calendars)
        if(!isNullOrUndefined(calendars)){
            Array.prototype.slice.call(calendars).map(element =>{
               return element.classList.add(inputStyle.react_datepicker_input_container)
            })
        }
        let calendarsWrappers = document.getElementsByClassName('react-datepicker-wrapper')
        //console.log('calendarsWrappers',calendarsWrappers)
        if(!isNullOrUndefined(calendarsWrappers)){
            Array.prototype.slice.call(calendarsWrappers).map(element =>{
              return element.classList.add(inputStyle.react_datepicker_input_wrappers)
            })
        }
        //agrega readonly a los iputs del datepicker
        let inputsDate = document.getElementsByClassName(inputStyle.input)
        if(!isNullOrUndefined(inputsDate)){
            Array.prototype.slice.call(inputsDate).map(element =>{
              return  element.readOnly = true;
            })
        }

    },[])

   /*  useEffect(()=>{
        if(isActive){
            dropButtonRef.current.classList.add(inputStyle.o_dropdown_button_active)
        }else{
            dropButtonRef.current.classList.remove(inputStyle.o_dropdown_button_active)
        }
    },[isActive]) */

    function splitDate(date){
        let stringDate =  date.split("/");
        // console.log('fecha formada', `${stringDate[1]}/${stringDate[0]}/${stringDate[2]}`)
        return new Date(`${stringDate[1]}/${stringDate[0]}/${stringDate[2]}`)
    }

    const [startDate,setStartDate] = useState(inputs[name] === '' ? null : splitDate(inputs[name]));
    const input = useRef(null)

    function getFormattedDate(date) {
        // console.log('fecha a formatear', date)
        // console.log('es valida fech',Object.prototype.toString.call(date) === "[object Date]")
            var year = date.getFullYear();
            
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;
            
            var day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;
            
            return day + '/' + month + '/' + year;
    }

    function handleOnChange(value) {
		// const selectValue = filterDropDownValue(itemId);
        // setFilterItem(selectValue[0])
        // console.log('value enviado al onChange', value)
        setStartDate(value);
        input.current.value = value;
        let finalValue;
        if(isNullOrUndefined(value)){
            finalValue = '';
        }else{
            finalValue = getFormattedDate(new Date(input.current.value));
        }
        const valueForInput = {
            isDate: true,
            target: {
                name: input.current.name,
                value: finalValue
            }
        }
        _onChange(valueForInput);
        //refDatePicker.current.setOpen(false)
    }

    // const ExampleCustomInput = ({ value, onClick }) => (
    //     <button className="example-custom-input" onClick={onClick}>
    //       {value}
    //     </button>
    //   );

    const handleClickDateContainer = () =>{
        //console.log('open state',refDatePicker.current)
        if(refDatePicker.current.state.open){
            iconContainerRef.current.classList.add(inputStyle.icon__container_active)
        }else{
            refDatePicker.current.setOpen(true)
            refDatePicker.current.setFocus(true)
            iconContainerRef.current.classList.add(inputStyle.icon__container_active)
        }
        //console.log('blur state', )
        //console.log('click on date container', refDatePicker.current.state.blur)
    }

    const handlerOnBlur = () =>{
        //console.log('handlerOnBlur ->>>>>>>>>>>>>>>')
        iconContainerRef.current.classList.remove(inputStyle.icon__container_active)
    }

    return (
        <div className={inputStyle.input__wrapper}>
            <div className={inputStyle.input__container} onClick={handleClickDateContainer}>
                <DatePicker
                    ref={refDatePicker}
                    showPopperArrow={true}
                    className={inputStyle.input}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    popperPlacement={_popperPlacement}
                    selected={startDate}
                    value={inputs.name}
                    onChange={handleOnChange}
                    locale="es"
                    onBlur={handlerOnBlur}
                    popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}/>
                <input ref={input} type="hidden" name={name} id={name} required={required} value={inputs.name}/>
                <div ref={iconContainerRef} className={inputStyle.icon__container}>
                    <IconArrow/>
                </div>
            </div>
            <p className={inputStyle.warning__required}>{ error ? error : null}</p>
        </div>
    );
};

export default InputDate; 
