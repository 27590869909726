import React from 'react'
import maintenanceTitleStyles from '../../styles/_components/_titles/_maintenance_title.module.scss'
import CalorexLogo from '../svg/commons/calorex_logo'

function MaintenanceTitle({step, getStepText, title_text="", contact}) {
    
    return (
            <> 
            {
                contact ?
                <div className={maintenanceTitleStyles.header__container}>
                    <div className={maintenanceTitleStyles.header__icon}>
                            <CalorexLogo />
                    </div>
                    <h1 className={`${maintenanceTitleStyles.header__title} ${maintenanceTitleStyles.header__title_step1}`}>{title_text}</h1>
                    <br/>
                    <h2 className={`maintenance_header_subtitle ${maintenanceTitleStyles.header__subtitle}`}>{getStepText(step)}</h2>
                </div>
                :
                <div className={step === 0 ? maintenanceTitleStyles.header__container_0 : maintenanceTitleStyles.header__container}>
                    {
                        step === 0 ?
                        <>
                            <div className={maintenanceTitleStyles.header__icon}>
                                <CalorexLogo />
                            </div>
                            <h1 className={maintenanceTitleStyles.header__title}>{title_text}</h1>
                            <h2 className={`maintenance_header_subtitle ${maintenanceTitleStyles.header__subtitle}`}>{getStepText(step)}</h2>
                        </>
                        :
                        <>
                            <h2 className={`maintenance_header_subtitle ${maintenanceTitleStyles.header__subtitle}`}>{getStepText(step)}</h2>
                            <h1 className={`${maintenanceTitleStyles.header__title} ${maintenanceTitleStyles.header__title_step1}`}>{title_text}</h1>
                        </>
                    }
               </div>
            }
            </>
        )
        

}

export default MaintenanceTitle
