import React, { useState, useRef, useEffect } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick'
import IconArrow from '../svg/commons/arrow_head';
import styles from "../../styles/_components/_maintenance/_dropdown_form.module.scss";

const DropdownForm = ({items, default_item = "name item", handleItemClick, _className="", type=1, disabled=false, isDropdownModels=false, isEnergyDropdown=false}) => {
    const [isActive, setActive] = useState(false);
    const wrapperRef = useRef(null);
    const dropButtonRef = useRef(null);
    useOutsideClick(wrapperRef, setActive);

    //useEffect for add and remove class for z_index=1
    useEffect(()=>{
        if(isActive){
            wrapperRef.current.classList.add(styles.o_dropdown_active);
            dropButtonRef.current.classList.add(styles.o_dropdown_button_active)
        }else{
            wrapperRef.current.classList.remove(styles.o_dropdown_active);
            dropButtonRef.current.classList.remove(styles.o_dropdown_button_active)
        }
    },[isActive])

    useEffect(()=>{
        if(disabled) {
            wrapperRef.current.classList.add(styles.disabled)
            return
        }
        wrapperRef.current.classList.remove(styles.disabled)

    },[disabled])

    function handleClick(item) {
        // console.log('handleClick -------zzzzzzzzzz',item);
        if(type === 1) {
            handleItemClick(item.uid);
        } else {
            handleItemClick(item);
        }
        setActive(false);
    }

    function getItems() {
        // console.log('isDropdownModels getItems', isDropdownModels )
        if(isDropdownModels){
            return  <div className={`${styles.o_dropdown_list} ${styles.o_dropdown_list_models}`}>
                        <div className={`${styles.o_dropdown_list_wrapper} ${styles.o_dropdown_list_wrapper_models}`} onClick={() => setActive(!isActive)}>
                            <ul>
                                {
                                    items.map((item, key) => {
                                        return(
                                            <li key={key} className={styles.o_dropdown_models_list}>
                                                <span>{item.name}</span>
                                                {item.versions.map(version => <p key={version.uid} onClick={() => handleClick(version)}>{version.data.title.text}</p>)}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>    
                    </div>
        }else{
            return  <div className={styles.o_dropdown_list}>
                        <div className={styles.o_dropdown_list_wrapper} onClick={() => setActive(!isActive)}>
                            <ul className={isEnergyDropdown ? styles.o_dropdown_energy_ul : ''}> 
                                {items.map( item => <li key={item.uid} onClick={() => handleClick(item)}>{item.value}</li>)}
                            </ul>
                        </div>    
                    </div>
        }
    }

    return (
        <div className={styles.o_dropdown} ref={wrapperRef}>
            <div ref={dropButtonRef} className={`${styles.o_dropdown_button} ${_className}`} onClick={() => setActive(!isActive)} >
                <p>{default_item}</p>
                <IconArrow />
            </div>
            {
                isActive && getItems()
            }
        </div>
    );
};

export default DropdownForm;